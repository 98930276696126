import React from 'react'
import { Container, Table } from 'react-bootstrap'
import '../../styles/hostel.css'
import hostel1 from '../../images/campus/hostel/IMG-20210714-WA0004.jpg'
import hostel2 from '../../images/campus/hostel/IMG-20210714-WA0005.jpg'
import { useEffect } from 'react'
import AOS from 'aos';
import Head from '../Head';



function Hostel() {

    useEffect(() => {
        AOS.init({
          duration : 1500
        });
        AOS.refresh();
    
    }, []);

  return (
        <div className='overflow-hidden '>
            <Head title="Hostel"/>
                        
            <Container className="px-5" >
                <header className="sectionHeader halfBorder mb-5">Hostel</header>

                <section>
                    <div className='hostelFeatures'>
                        <p className="sectionContent">
                            The college has hostel facility for both Boys & Girls,We provide separate hostels for boys and girls.We ensure safe and secured environment in hostels.
                            <br />
                            Tuition classes and regular tests will be held for the students during morning and evening hours by experience lecturer.
                        </p>
                        <br />
                        <ul>
                            <li>Food served is strictly vegetarian.</li>
                            <li>Well-trained security staff provides security to our hostel students 24 hours, round the clock.</li>
                            <li>Strict discipline is maintained inside the hostels. Girl students are permitted to leave the campus only with their parents / Guardian</li>
                            <li>Uninterrupted power supply.</li>
                            <li>Medical facilities.</li>
                        </ul>
                    </div>

                    <div className='d-flex justify-content-center tableHostel' data-aos="flip-left" data-aos-duration='2000'>
                        <Table  className='table  text-center   table-striped table-bordered '>
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Work</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>4.45 am</td>
                                    <td>Wakeup Call</td>
                                </tr>
                                <tr>
                                    <td>5.00 am - 7.00 am</td>
                                    <td>Study Hours</td>
                                </tr>
                                <tr>
                                    <td>7.00 am - 8.00 am</td>
                                    <td>Bath and Change</td>
                                </tr>
                                <tr>
                                    <td>8.00 am - 8.30 am</td>
                                    <td>Breakfast</td>
                                </tr>
                                <tr>
                                    <td>8.30 am - 1.15 pm</td>
                                    <td>College</td>
                                </tr>
                                <tr>
                                    <td>1.15 pm - 2.00 pm</td>
                                    <td>Lunch</td>
                                </tr>
                                <tr>
                                    <td>2.00 pm - 5.30 pm</td>
                                    <td>College</td>
                                </tr>
                                <tr>
                                    <td>5.30 pm - 6.30 pm</td>
                                    <td>Snacks, Sports & Games</td>
                                </tr>
                                <tr>
                                    <td>6.30 pm - 8.30 pm</td>
                                    <td>Study Hours</td>
                                </tr>
                                <tr>
                                    <td>8.30 pm - 9.30 pm</td>
                                    <td>Dinner and Phone calls</td>
                                </tr>
                                <tr>
                                    <td>9.30 pm - 11 pm</td>
                                    <td>Home works</td>
                                </tr>
                                <tr>
                                    <td>11.00 pm</td>
                                    <td>Lights off</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                   
                   

                </section>

                <section>
                    <div className="gallery d-flex flex-wrap"> 
                        <figure data-aos="fade-up-right">
                            <img fluid  src={hostel1} alt="hostel" />
                        </figure>   
                        <figure data-aos="fade-up-left">
                            <img fluid  src={hostel2} alt="hostel" />
                        </figure>   
                        <figure data-aos="fade-up-right">
                            {/* <img fluid  src={hostel3} alt="hostel" /> */}
                        </figure>
                        <figure data-aos="fade-up-left">
                            {/* <img fluid  src={hostel4} alt="hostel" /> */}
                        </figure>   
                    
                    </div>
                </section>
                

                    
            </Container>

        </div>
  )
}

export default Hostel